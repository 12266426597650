body {

}

a {
	color: #000;
}

#wrapper {
	max-width: 1020px;
	margin: 0px auto;
	padding: 20px 0;
}

#header {
	padding: 20px;
	background: #eee;
	border-radius: 10px;
}

#footer {
	padding: 20px;
	background: #eee;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	line-height: 1.5;

	.col:nth-child(2) {
		text-align: right;
	}
}

#main {
	padding: 20px;
}